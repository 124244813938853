<template>
  <div></div>
</template>

<style lang="scss" scoped>
.spinner.spinner-right {
  padding-right: 3.5rem !important;
}
</style>

<script>
import { mapState } from "vuex";
import JwtService from "@/core/services/jwt.service";
import CustomerService from "../../../services/customer.service";
import { LOGIN, LOGOUT } from "../../../core/services/store/auth.module";

export default {
  name: "token",
  data() {
    return {};
  },
  mounted: function () {
    JwtService.saveToken(this.$route.query.token);
    this.getUserInfo();
  },
  methods: {
    getUserInfo() {
      CustomerService.getUserInfo()
        .then((result) => {
          this.$store.dispatch(LOGIN, result.data);
          this.$router.push({ name: "customers" });
        })
        .catch(() => {
          this.$store.dispatch(LOGOUT);
        });
    },
  },
  computed: {
    ...mapState({
      //errors: (state) => state.auth.errors,
    }),
  },
};
</script>
